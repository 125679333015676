import React from 'react';
import CookiesPolicy from '../components/cookiesPolicy';


const Cookies = () => {
  return (
    <CookiesPolicy />
  )
}

export default Cookies;