
import React from 'react';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';

const CookiesPolicy = () => {
  const PAGE = "Cookies Policy";

  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="policy-backdrop hero light">
        <div className="content">

          <h2> Cookies Policy</h2>
          <section>
            <p><strong>As is common practice with almost all professional websites
            this site uses cookies, which are tiny files that are downloaded
            to your computer, to improve your experience.</strong></p>

            <p>This page describes what information they gather, how we use it
            and why we sometimes need to store these cookies. We will also share
            how you can prevent these cookies from being stored however
            this may downgrade or 'break' certain elements of the
            sites functionality.</p>

            <p>We use cookies for a variety of reasons detailed below. Unfortunately
            in most cases there are no industry standard options for disabling
            cookies without completely disabling the functionality and features
            they add to this site. It is recommended that you leave on all
            cookies if you are not sure whether you need them or not in case
            they are used to provide a service that you use.</p>

            <h3>Disabling Cookies</h3>

            <p><strong>You can prevent the setting of cookies by adjusting
            the settings on your browser (see your browser Help for how to do this).</strong></p>

            <p>Be aware that disabling cookies will affect the functionality of this
            and many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the site.
            Therefore it is recommended that you do not disable cookies.</p>

            <h3>The Cookies We Set</h3>

            <p>
              <strong>This site offers opt-in newsletter and email subscription
              services and cookies may be used to remember if you are already
              registered and whether to show certain notifications which might
              only be valid to subscribed/unsubscribed users.</strong></p>

            <p>When you submit data to through a form such as those found
            on contact pages or comment forms cookies may be set to remember
            your user details for future correspondence.</p><p>In some special
            cases we also use cookies provided by trusted third parties. The
            following section details which third party cookies you might
            encounter through this site.</p>

            <p>This site uses Google Analytics which is one of the most widespread
            and trusted analytics solution on the web for helping us to understand
            how you use the site and ways that we can improve your experience. These
            cookies may track things such as how long you spend on the site and the
            pages that you visit so we can continue to produce engaging content.</p>

            <p>For more information on Google Analytics cookies, see the official&nbsp;
              <a
                className="yellow-link"
                title="Read Google&#039;s guide on Analytics cookies"
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                target="_blank"
                rel="noopener noreferrer">Google Analytics page</a>.
            </p>

            <p>Third party analytics are used to track and measure usage of this site
            so that we can continue to produce engaging content. These cookies may
            track things such as how long you spend on the site or pages you visit
            which helps us to understand how we can improve the site for you.</p>

            <p>From time to time we test new features and make subtle changes to the
            way that the site is delivered. When we are still testing new features
            these cookies may be used to ensure that you receive a consistent
            experience whilst on the site whilst ensuring we understand which
            optimisations our users appreciate the most.</p>

            <p>In some cases we may provide you with custom content based on what
            you tell us about yourself either directly or indirectly by linking
            a social media account. These types of cookies simply allow us to
            provide you with content that we feel may be of interest to you.</p>

            <p>We also use social media buttons and/or plugins on this site that
            allow you to connect with your social network in various ways. For
            these to work the following social media sites including; Facebook,
            Twitter, LinkedIn, Google Plus, Pinterest, will set cookies through
            our site which may be used to enhance your profile on their site or
            contribute to the data they hold for various purposes outlined in
            their respective privacy policies.</p>

            <h3>More Information</h3>

            <p>Hopefully that has clarified things for you and as was previously
            mentioned if there is something that you aren't sure whether
            you need or not it's usually safer to leave cookies enabled
            in case it does interact with one of the features you use on our
            site. However if you are still looking for more information you can
            contact us through one of our preferred contact methods.</p>

            <p>Email: <a className="yellow-link" href="mailto:privacy@vintagesixmedia.com">privacy@vintagesixmedia.com</a></p>
          </section>
        </div>
      </div>
    </Layout >
  )
}

export default CookiesPolicy;